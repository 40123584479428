.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  left: 0;
  width: 100%;
  background: #fff;
  transition: top 0.5s ease-in-out;
  z-index: 8000;
  box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  padding: 1rem 2rem;

  @media (min-width: 768px) {
    top: 0 !important; }

  @media(max-width: 480px) {
    flex-direction: column-reverse; }

  h2 {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;

    @media(max-width: 480px) {
      font-size: 1.6rem; } } }

.titleWrapper {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;

  @media(max-width: 480px) {
    position: relative;
    margin-top: 1rem; } }

.logo {
  // position: absolute
  right: 60px;
  height: 70px;
  width: auto;

  @media(max-width: 480px) {
    height: 40px;
    right: 20px; } }
