.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase; } }

.section {
  padding: 4rem 2rem; }

.sectionGray {
  background-color: #f9f9f9; }

.sectionDark {
  background-color: #414141;
  color: #fff; }

.heart {
  margin-bottom: 2rem; }

.sectionCta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  text-align: center; }

.subtitle {
  text-align: center; }
