.radarFilters {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    margin: 0 0.5rem; }

  @media(max-width: 480px) {
    flex-direction: column;

    & > * {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; } } }
