
$size: 30;
$heartColor: #e41f28;

.heartWrapper {
  position: relative;
  width: $size*2 + 0px;
  height: 50px; }

.heartText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  font-size: 1.6rem;
  font-family: Helvetica, 'Open Sans', sans-serif; }

.heart {
  width: $size + 0px;
  height: $size + 0px;
  transform: rotate(45deg);
  background-color: $heartColor;
  left: $size/2 + 0px;
  top: $size/2 + 0px;
  transform-origin: center;

  &:before,
  &:after {
    position: absolute;
    width: $size + 0px;
    height: $size + 0px;
    content: '';
    border-radius: 50%;
    background-color: $heartColor; }

  &:before {
    bottom: 0px;
    left: -($size/2) + 0px; }

  &:after {
    top: -($size/2) + 0px;
    right: 0px; } }
