$lineHeight: 10;

.wrapper {}

.lineWrapper {
  position: relative; }

.line {
  width: 100%;
  height: $lineHeight + 0px;
  background-color: #333E48; }

.circleWrapper {
  position: absolute;
  top: -1 * $lineHeight + 0px; }

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%; }

.value {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%); }

.scalaValues {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media(max-width: 768px) {
    flex-direction: column; } }

.scalaValuesWrapper {
  margin-top: 6rem;
  text-align: center; }

.scalaValueWrapper {
  display: flex;
  padding: 0 1rem;
  flex: 1;
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  width: 20%;
  flex-basis: 20%;

  @media(max-width: 768px) {
    width: 100%;
    flex-basis: 100%;
    padding: 0;
    margin-bottom: 2rem; }

  &:first-child {
    padding-left: 0; }

  &:last-child {
    padding-right: 0; } }

.scalaValue {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  max-width: 100%; }

.scalaValueTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  min-height: 40px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  text-align: center;
  padding: 1rem;
  font-size: 12px; }

.scalaValueDescription {
  padding: 1rem;
  font-size: 1.4rem; }

.title {
  text-align: center;
  margin-bottom: 6rem;
  font-size: 2rem; }

.options {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 3rem;

  & > div {
    padding: 0rem 1rem; }

  @media(max-width: 768px) {
    font-size: 1.2rem; } }
