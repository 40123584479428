$progressHeight: 30;
$red: #e53935;
$yellow: #ffc107;
$green: #00c853;

.progress {
  height: $progressHeight + 0px;
  overflow: hidden;
  background-color: #e9e9e9;
  padding: 0px;
  border-radius: 0;
  width: 100px;
  // border-top-left-radius: 8px
  // border-top-right-radius: 8px
  // border-style: solid
  // border-color: #333E48
  // border-top-width: 4px
  // border-left-width: 4px
  // border-bottom-width: 4px
  text-align: center; }

.progressBar {
  // background: #e41f28
  transition: width 1.5s ease-in-out;
  width: 0%;
  height: 100%;
  border-radius: 0; }

.progressBarLow {
  background: $red; }

.progressBarMedium {
  background: $yellow; }

.progressBarHigh {
  background: $green; }
