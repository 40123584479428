.content {
  margin-top: 80px; }

.scoreExplanationWrapper {
  padding: 2rem;
  text-align: center; }

.chartWrapper {
  display: flex;
  justify-content: center; }

.legend {
  margin-bottom: 4rem; }

.success {
  margin-bottom: 2rem;
  font-weight: bold;
  color: #088a08; }

.lineChartWrapper {
  width: 100%;
  height: 300px;
  margin-top: 6rem;
  margin-bottom: 6rem;
  text-align: center; }
