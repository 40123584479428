.button {
  background-color: #414141;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: .1rem;
  line-height: 3.8rem;
  padding: 0 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  white-space: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  max-width: 100%;

  &:focus {
    outline: 0; } }

.hover {
  &:hover,
  &:focus, {
    background-color: #ffffff;
    border: 1px solid #414141;
    color: #414141;
    outline: 0; }

  &:active {
    background-color: #414141;
    border: 1px solid transparent;
    color: #fff; } }

.outlined {
  background-color: #ffffff;
  border: 1px solid #414141;
  color: #414141;

  &:hover, {
    background-color: #414141;
    border: 1px solid transparent;
    color: #fff;
    outline: 0; } }

.rounded {
  border-radius: 2rem; }
