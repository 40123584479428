.wrapper {
  overflow-x: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);

  @media(max-width: 768px) {
    box-shadow: none;
    overflow: hidden; } }

.table {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  border-collapse: collapse;

  th, td {
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto; }

  @media(max-width: 768px) {
    background-color: transparent; }

  thead {
    tr {
      border-bottom: 1px solid #ddd;

      th {
        text-align: left;
        padding: 1rem; } } }

  tbody {
    tr {
      &:hover {
        background-color: rgba(0,0,0,0.05); }

      @media(max-width: 768px) {
        &:hover {
          background-color: transparent; } }

      th, td {
        text-align: left;
        padding: 1rem;
        border-bottom: 1px solid #ddd;
        display:table-cell {} } } } }

.center {
  display: flex;
  justify-content: center; }

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00c853;
  color: #fff;
  padding: 2rem; }

.groupScore {
  display: flex;
  flex-direction: row; }

.groupScorePercent {
  min-width: 35px; }

.benchmarkRow {
  background-color: rgba(0,0,0,0.04);
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;

  &:hover {
    background-color: rgba(0,0,0,0.04) !important; }

  @media(max-width: 768px) {
    background-color: rgba(0, 0, 0, 0.04);
    margin-top: 2rem;
    margin-bottom: 2rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important; } } }

.hidden {
  display: none;
  @media(max-width: 768px) {
    display: none;
    // Info: We still want the div to be there for the margin-bottom
    // in responsive view
    visibility: hidden;
    width: 0;
    height: 0; } }

@media(max-width: 768px) {
  table, thead, tbody, th, td, tr {
    display: block; }

  thead {
    tr {
      position: absolute;
      top: -9999px;
      left: -9999px; } }

  tr {
    border: 2px solid #bcbcbc;
    margin-bottom: 2rem; }

  td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 50% !important;

    &:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      width: 45%;
      white-space: nowrap;
      /* Label the data */
      content: attr(data-column);
      color: #000;
      font-weight: bold; }

    tbody {
      th, td {
        border-bottom: none; } } } }
