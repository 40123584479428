
@font-face {
  font-family: 'alte_din_1451_mittelschriftRg';
  src: url('./alte-din-1451-mittelschrift/din1451alt-webfont.woff2') format('woff2'), url('./alte-din-1451-mittelschrift/din1451alt-webfont.woff') format('woff'), url('./alte-din-1451-mittelschrift/din1451alt.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

*, *:before, *:after {
  box-sizing: inherit;
 }  // hyphens: auto

body {
  padding: 0;
  margin: 0;
  font-family: Helvetica, 'Open Sans', sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
  color: #414141; }

h2 {
  letter-spacing: 0.8px; }

a {
  color: #414141;
  font-weight: bold;
  text-decoration: none; }
