$columns: 12;
@for $i from 1 through $columns {
  .col#{$i} {
    flex-basis: 1% * 100 / $i;
    padding: 0.5rem 1rem;

    @media(max-width: 768px) {
      flex-basis: 100%; } } }

.exampleReport {
  width: 100%;
  max-width: 100%;
  height: auto; }

.exampleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.example {
  width: 75%;

  @media(max-width: 768px) {
    width: 100%; } }

.advancedReport {
  padding: 2rem; }

.explanationWrapper {
  display: flex;
  justify-content: center; }

.explanation {
  width: 90%; }

.cta {
  text-align: center; }

.checkboxGroupTitle {
  font-weight: bold; }

.form {
  display: flex;
  flex-wrap: wrap; }

.input {
  margin-bottom: 1rem !important; }

.score {
  text-transform: uppercase;
  font-family: 'alte_din_1451_mittelschriftRg';
  font-size: 6rem;
  display: block;
  line-height: 1.2; }

.scoreWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem; }
